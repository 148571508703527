import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('f-page-header',{attrs:{"title":_vm.title,"prevLinks":[]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('lms-page-header-action-btn',{attrs:{"icon":"mdi-plus"},on:{"click":_vm.create}},[_vm._v(" "+_vm._s(_vm.$tt.capitalize(_vm.$t('labels.create')))+" ")])]},proxy:true}])}),_c('f-container',{attrs:{"topBottom":""}},[_c('div',{directives:[{name:"allowed",rawName:"v-allowed",value:([_vm.userTypes.ADMIN]),expression:"[userTypes.ADMIN]"}],staticClass:"filters mb-8"},[_c('div',{staticClass:"filters__item"},[_c('f-master-select',{attrs:{"optional":"","isMaster":_vm.$store.state.user.isMaster,"countries":_vm.$store.state.country.list,"userMasters":_vm.$store.state.user.masters,"is-multiple":false,"select-attrs":{
            outlined: false,
            counter: false,
            solo: true,
            hideDetails: true,
          }},model:{value:(_vm.filters.main),callback:function ($$v) {_vm.$set(_vm.filters, "main", $$v)},expression:"filters.main"}})],1)]),(!_vm.flags.loaded)?_c(VRow,_vm._l((3),function(n){return _c(VCol,{key:n,attrs:{"cols":"4"}},[_c(VSkeletonLoader,{staticClass:"mx-auto",attrs:{"type":"image, article"}})],1)}),1):_vm._e(),(_vm.flags.loaded)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"kanban-list"},_vm._l((_vm.filteredList),function(item,index){return _c('div',{key:index,staticClass:"kanban-list-item"},[_c('f-team-list-item',{attrs:{"team":item,"progress":_vm.getUserTeamProgress(item),"is-admin":_vm.isManager},on:{"view":_vm.view,"edit":_vm.edit,"remove":_vm.remove,"changeStatus":_vm.changeStatus}})],1)}),0)]),(!_vm.isManager && _vm.filteredList.length === 0)?_c(VCol,[_c('p',[_vm._v(_vm._s(_vm.$t('labels.noTeams')))])]):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }