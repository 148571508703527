<template>
  <div :class="classes">
    <v-switch
      dense
      v-model="model"
      :disabled="disabled"
      hide-details
      @change="onChange"
    >
      <template v-slot:label>
        <span class="team-status__label">{{ statusLabel }}</span>
        <v-progress-circular
          v-if="loading"
          :indeterminate="switchMe"
          :value="0"
          size="24"
          class="ml-2"
        ></v-progress-circular>
      </template>
    </v-switch>
  </div>
</template>

<script>
export default {
  props: {
    status: String,
    loading: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      model: this.status === "enabled",
    };
  },
  computed: {
    classes() {
      return {
        "team-status": true,
      };
    },
    statusLabel() {
      return this.$i18n.t(`labels.status.${this.status}`);
    },
  },
  methods: {
    onChange() {
      const newStatus = this.model ? "enabled" : "disabled";
      this.$emit("change", newStatus);
    },
  },
  watch: {
    status: function (val) {
      this.$nextTick(() => {
        this.model = val === "enabled";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.team-status {
  &__label {
    font-size: 14px;
  }
}
</style>
