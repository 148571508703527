<template>
  <div>
    <f-page-header :title="title" :prevLinks="[]">
      <template #actions>
        <lms-page-header-action-btn icon="mdi-plus" @click="create">
          {{ $tt.capitalize($t('labels.create')) }}
        </lms-page-header-action-btn>
      </template>
    </f-page-header>

    <f-container topBottom>
      <div class="filters mb-8" v-allowed="[userTypes.ADMIN]">
        <div class="filters__item">
          <f-master-select
            v-model="filters.main"
            optional
            :isMaster="$store.state.user.isMaster"
            :countries="$store.state.country.list"
            :userMasters="$store.state.user.masters"
            :is-multiple="false"
            :select-attrs="{
              outlined: false,
              counter: false,
              solo: true,
              hideDetails: true,
            }"
          />
        </div>
      </div>
      <v-row v-if="!flags.loaded">
        <v-col v-for="n in 3" :key="n" cols="4">
          <v-skeleton-loader class="mx-auto" type="image, article">
          </v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-if="flags.loaded">
        <v-col cols="12">
          <div class="kanban-list">
            <div
              class="kanban-list-item"
              v-for="(item, index) in filteredList"
              :key="index"
            >
              <f-team-list-item
                :team="item"
                :progress="getUserTeamProgress(item)"
                :is-admin="isManager"
                @view="view"
                @edit="edit"
                @remove="remove"
                @changeStatus="changeStatus"
              />
            </div>
          </div>
        </v-col>
        <v-col v-if="!isManager && filteredList.length === 0">
          <p>{{ $t('labels.noTeams') }}</p>
        </v-col>
      </v-row>
    </f-container>
  </div>
</template>

<script lang="js">
import TeamListItem from "../../components/Teams/TeamListItem.vue";
import MasterSelect from "../../components/shared/MasterSelect.vue";
import { UserType } from "../../helpers/user_utils";

export default {
  components: {
    "f-team-list-item": TeamListItem,
    "f-master-select": MasterSelect,
  },
  data: () => ({
    list: [],
    flags: {
      loaded: false,
    },
    filters: {
      main: null,
    },
    userProgress: {},
    team: {},
  }),
  async created() {
    this.get();
  },
  methods: {
    async get() {
      this.$store.commit("system/loading");
      const result = await this.$store.dispatch("team/loadList");
      this.$store.commit("system/loading", false);
      const isAdmin = this.$store.state.auth.user.type == UserType.Admin;
      if (!isAdmin) {
        await Promise.all(
          result.map(async (team) => {
            // const id = team._id;
            // @TODO: Perguntar o que deveria ser isso
            // const progress = await this.$store.dispatch(
            //   "progress/loadTeamProgress",
            //   id
            // );
            // this.userProgress[id] = progress;
          })
        );
      }

      this.list = result;
      this.flags.loaded = true;
    },
    create() {
      this.$router.push({
        name: "team-create",
      });

      this.$gtm.trackEvent({
        category: 'teams-admin-actions',
        event: 'click',
        action: 'teams-click',
        label: 'create-teams',
        value: 'new',
      });
    },
    view(id) {
      this.$router.push({
        name: "team-view",
        params: { id },
      });

      this.$gtm.trackEvent({
        category: 'teams-admin-actions',
        event: 'click',
        action: 'teams-click',
        label: 'teams-new',
        value: `view-${id}`,
      });
    },
    edit(id) {
      this.$router.push({
        name: "team-edit",
        params: { id },
      });

      this.$gtm.trackEvent({
        category: 'teams-admin-actions',
        event: 'click',
        action: 'teams-click',
        label: 'teams-edit',
        value: `edit-${id}`,
      });
    },
    async remove(id) {
      var response = await this.$store.dispatch("dialog/show", {
        title: this.$t("alerts.teams.remove.title"),
        content: this.$t("alerts.teams.remove.content"),
      });
      if (response) {
        await this.$store.dispatch("team/remove", id);
        this.get();

        this.$gtm.trackEvent({
          category: 'teams-admin-actions',
          event: 'click',
          action: 'teams-click',
          label: 'teams-remove',
          value: `remove-${id}`,
        });
      }
    },
    getUserTeamProgress(team) {
      return this.userProgress[team._id] ? this.userProgress[team._id] : [];
    },
    async changeStatus(id, status) {
      await this.$store.dispatch("team/update", {
        _id: id,
        status: status
      });
      this.list = this.list.map((item) => {
        if (item._id === id) {
          return { ...item, status };
        }
        return item;
      });

      this.$gtm.trackEvent({
        category: 'teams-admin-actions',
        event: 'click',
        action: 'teams-click',
        label: 'change-status',
        value: `${id}-${status}`,
      });
    },
  },
  computed: {
    title() {
      return this.$t("labels.teams")
    },
    isManager() {
      return this.$store.state.auth.user.type == UserType.Admin || this.$store.state.auth.user.type == UserType.Master;
    },
    filteredList() {
      return this.list.filter((item) => {
        if (this.filters.main) {
          return item.masters.find(it=>it._id==this.filters.main)
        }
        return true;
      });
    },
    filterLabel() {
      return this.$i18n.t("labels.filterMaster");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-plus {
  height: 100%;
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  &__link {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    i {
      color: rgba(0, 0, 0, 0.25);
      font-size: 180px;
    }
  }
}
.filters {
  &__item {
    max-width: 300px;
    display: inline-block;
    border: 1px solid #dde1e4;
  }
}
</style>
